import { createStore, applyMiddleware, combineReducers } from "redux";
import { thunk } from "redux-thunk";
import {
  reducerGetCategories,
  reducerGetNews,
  reducerGetProjects,
} from "../reducer/reducerGetData";

const rootReducer = combineReducers({
  projects: reducerGetProjects,
  categories: reducerGetCategories,
  news: reducerGetNews,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
