import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getProjects,
  getCategories,
  getNews,
} from "./context/actions/getaction";
import Navbar from "./components/layouts/Navbar";
import Footer from "./components/layouts/Footer";
import Home from "./components/pages/home";
import About from "./components/pages/about";
import Contact from "./components/pages/contact";
import News from "./components/pages/news";
import Property from "./components/pages/projects";
import "./index.css";
import Projectdetails from "./components/pages/projects/projectdetails";
import { animateScroll as scroll } from "react-scroll";
import AfterService from "./components/pages/services/After-sales ";
import Nationality from "./components/pages/services/Nationality";
import Residence from "./components/pages/services/Residence";
import Real from "./components/pages/services/Real";
import NewsDetails from "./components/pages/news/NewsDetails";

function App() {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.projects);

  useEffect(() => {
    dispatch(getProjects());
    dispatch(getCategories());
    dispatch(getNews());
  }, [dispatch]);

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 50,
      smooth: "easeInOutQuint",
    });
  };
  return (
    <div className="container-xxl bg-white p-0 tajawal-regular">
      <BrowserRouter>
        <Navbar />
        {/* حالة الانتظار */}
        {loading && (
          <div className="text-center my-5">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        {/* حالة الخطأ */}
        {error && (
          <div className="alert alert-danger text-center my-5" role="alert">
            {error}
          </div>
        )}
        {/* عرض المحتوى فقط عندما لا يكون هناك تحميل أو خطأ */}
        {!loading && !error && (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/service_after_sales" element={<AfterService />} />
            <Route path="/service_nationality" element={<Nationality />} />
            <Route path="/service_residence" element={<Residence />} />
            <Route path="/service_real" element={<Real />} />
            <Route path="/projects" element={<Property />} />
            <Route path="/project_details/:id" element={<Projectdetails />} />
            <Route path="/news" element={<News />} />
            <Route path="/news_details/:id" element={<NewsDetails />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        )}
        <Footer />
      </BrowserRouter>
      <a
        onClick={() => {
          scrollToTop();
        }}
        className="btn btn-lg btn-primary btn-lg-square back-to-top"
      >
        <i className="bi bi-arrow-up" />
      </a>
    </div>
  );
}

export default App;
