import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { useTranslation } from "react-i18next";

function Projects() {
  const [filter, setFilter] = useState(null);
  const { t } = useTranslation("global");
  const { projects } = useSelector((state) => state.projects);
  const data = filter
    ? projects.filter((i) => i.sell_or_rent == filter)
    : projects;
  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 50,
      smooth: "easeInOutQuint",
    });
  };

  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="row g-0 gx-5 align-items-end">
          <div className="col-lg-6">
            <div
              className={`"text-end mx-auto mb-5 wow slideInLeft`}
              data-wow-delay="0.1s"
            >
              <h1 className="mb-3">{t("property.heading")}</h1>
              <p>{t("property.description")}</p>
            </div>
          </div>
          <div
            className="col-lg-6 text-start text-lg-end wow slideInRight"
            data-wow-delay="0.1s"
          >
            <ul className="nav nav-pills d-inline-flex justify-content-end mb-5">
              <li className="nav-item me-2">
                <a
                  onClick={() => {
                    setFilter("");
                  }}
                  className="btn btn-outline-primary active"
                  data-bs-toggle="pill"
                  href="#tab-1"
                >
                  {t("property.featured")}
                </a>
              </li>
              <li className="nav-item me-2">
                <a
                  onClick={() => {
                    setFilter("sale");
                  }}
                  className="btn btn-outline-primary"
                  data-bs-toggle="pill"
                  href="#tab-2"
                >
                  {t("property.for_sell")}
                </a>
              </li>
              <li className="nav-item me-0">
                <a
                  onClick={() => {
                    setFilter("rent");
                  }}
                  className="btn btn-outline-primary"
                  data-bs-toggle="pill"
                  href="#tab-3"
                >
                  {t("property.for_rent")}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="tab-content">
          <div id="tab-1" className="tab-pane fade show p-0 active">
            <div className="row g-4">
              {data.slice(0, 6).map((item, key) => {
                const img = item.images[0];

                return (
                  <div
                    key={key}
                    className="col-lg-4 col-md-6 wow fadeInUp"
                    data-wow-delay="0.1s"
                  >
                    <div className="property-item rounded overflow-hidden h-100 d-flex flex-column">
                      <div className="position-relative overflow-hidden">
                        <Link
                          onClick={scrollToTop}
                          to={`/project_details/${item.id}`}
                        >
                          <img
                            className="img-fluid"
                            src={`https://sima.alayadi.site/project_imgs/${img.photo}`}
                            alt=""
                          />
                        </Link>
                        <div className="bg-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">
                          {item[t("property.sell")]}
                        </div>
                        <div className="bg-white rounded-top text-primary position-absolute start-0 bottom-0 mx-4 pt-1 px-3">
                          {item.categori[t("category.categori")]}
                        </div>
                      </div>
                      <div className="p-4 pb-0 flex-grow-1">
                        {item.price && (
                          <h5 className="text-primary mb-3">${item.price}</h5>
                        )}
                        <Link
                          onClick={scrollToTop}
                          to={`/project_details/${item.id}`}
                          className="d-block  mb-2"
                        >
                          {item[t("property.title")]}
                        </Link>
                        <p>
                          <i className="fa fa-map-marker-alt text-primary me-2" />
                          {item[t("property.ready")]}
                        </p>
                      </div>
                      <div className="d-flex border-top">
                        <small className="flex-fill text-center border-end py-2">
                          <i className="fa fa-ruler-combined text-primary me-2" />
                          {item.m2} {t("property.m2")}
                        </small>
                        <small className="flex-fill text-center border-end py-2">
                          <i className="fa fa-bed text-primary me-2" />
                          {item.room} {t("property.rooms")}
                        </small>
                        <small className="flex-fill text-center border-end py-2">
                          <i className="fa fa-bath text-primary me-2" />
                          {item.lavabo} {t("property.bathrooms")}
                        </small>
                        <small className="flex-fill text-center py-2">
                          <i className="fa fa-ruler-combined text-primary me-2" />
                          {item[t("property.empty")]}
                        </small>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
