import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import Logo from "../images/logo.png";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const [lang, setLang] = useState(true);
  const { t, i18n } = useTranslation("global");
  const location = useLocation();
  const { pathname } = location;

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 50,
      smooth: "easeInOutQuint",
    });
  };

  const handleLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="container-fluid nav-bar bg-transparent">
      <nav className="navbar navbar-expand-lg bg-white navbar-light py-0 px-4">
        <Link
          onClick={scrollToTop}
          to="/"
          className="navbar-brand d-flex align-items-center text-center"
        >
          <div className=" p-2 me-2 ">
            <img
              className="img-fluid "
              src={Logo}
              alt={t("navbar.logo_alt")}
              style={{ width: 110 }}
            />
          </div>
        </Link>
        <button
          type="button"
          className="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto">
            <Link
              onClick={scrollToTop}
              to="/"
              className={`nav-item nav-link ${
                pathname === "/" ? "active" : ""
              }`}
            >
              {t("navbar.home")}
            </Link>
            <Link
              onClick={scrollToTop}
              to="/about"
              className={`nav-item nav-link ${
                pathname === "/about" ? "active" : ""
              }`}
            >
              {t("navbar.about")}
            </Link>
            <div className="nav-item dropdown">
              <Link
                className={`nav-link dropdown-toggle ${
                  pathname.startsWith("/service") ? "active" : ""
                }`}
                data-bs-toggle="dropdown"
                to="#"
              >
                {t("navbar.services")}
              </Link>
              <div className="dropdown-menu rounded-0 m-0">
                <Link
                  onClick={scrollToTop}
                  to="/service_after_sales"
                  className="dropdown-item"
                >
                  {t("navbar.after_sales")}
                </Link>
                <Link
                  onClick={scrollToTop}
                  to="/service_nationality"
                  className="dropdown-item"
                >
                  {t("navbar.nationality")}
                </Link>
                <Link
                  onClick={scrollToTop}
                  to="/service_residence"
                  className="dropdown-item"
                >
                  {t("navbar.residence")}
                </Link>
                <Link
                  onClick={scrollToTop}
                  to="/service_real"
                  className="dropdown-item"
                >
                  {t("navbar.real_estate_development")}
                </Link>
              </div>
            </div>
            <Link
              onClick={scrollToTop}
              to="/projects"
              className={`nav-item nav-link ${
                pathname === "/projects" ? "active" : ""
              }`}
            >
              {t("navbar.projects")}
            </Link>
            <Link
              onClick={scrollToTop}
              to="/news"
              className={`nav-item nav-link ${
                pathname === "/news" ? "active" : ""
              }`}
            >
              {t("navbar.news")}
            </Link>
            <Link
              onClick={scrollToTop}
              to="/contact"
              className={`nav-item nav-link ${
                pathname === "/contact" ? "active" : ""
              }`}
            >
              {t("navbar.contact")}
            </Link>
          </div>
          <button
            onClick={() => {
              handleLanguage(lang ? "en" : "ar");
              setLang(!lang);
            }}
            className="btn btn-primary px-3 d-none d-lg-flex"
          >
            {lang
              ? t("navbar.language_toggle.english")
              : t("navbar.language_toggle.arabic")}{" "}
          </button>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
