import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import img1 from "../../images/01.jpg";
import img2 from "../../images/02.jpg";
import img3 from "../../images/03.jpg";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Header() {
  const { t } = useTranslation("global");

  return (
    <div className="container-fluid header bg-white p-0">
      <div className="row g-0 align-items-center flex-column-reverse flex-md-row">
        <div dir={t("dir")} className="col-md-6 p-5 mt-lg-5">
          <h2 className=" animated fadeIn mb-4">
            {t("header.main_heading1")}
            <span className="text-primary"> {t("header.main_heading2")} </span>
          </h2>
          <p className="animated fadeIn mb-4 pb-2">
            {t("header.sub_heading1")}
          </p>
          <p className="animated fadeIn mb-4 pb-2">
            {t("header.sub_heading2")}
          </p>
          <Link
            to={"/about"}
            className="btn btn-primary py-3 px-5 me-3 animated fadeIn"
          >
            {t("header.read_more")}
          </Link>
        </div>
        <div className="col-md-6 animated fadeIn">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ clickable: true }}
            autoplay={{ delay: 3000 }}
            className="header-swiper"
          >
            <SwiperSlide>
              <img
                className="img-fluid w-100 rounded h-100"
                src={img1}
                alt="Carousel 1"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="img-fluid w-100 rounded h-100 "
                src={img2}
                alt="Carousel 2"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="img-fluid w-100 rounded h-100"
                src={img3}
                alt="Carousel 2"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default Header;
