import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Img from "../../images/about.jpg";

const About = () => {
  const { t } = useTranslation("global");

  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="bg-light rounded p-3">
          <div
            className="bg-white rounded p-4"
            style={{ border: "1px dashed rgba(0, 185, 142, .3)" }}
          >
            <div className="row g-5 align-items-center">
              <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                <img className="img-fluid rounded w-100" src={Img} alt="" />
              </div>
              <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                <div className="mb-4">
                  <h1 className="mb-3">{t("about.heading")}</h1>
                  <p>{t("about.description1")}</p>
                  <p>{t("about.description2")}</p>
                </div>
                <Link to={"/about"} className="btn btn-primary py-3 px-4 me-2">
                  {t("about.read_more")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
