import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import "../../../index.css";

function Projectdetails() {
  const { id } = useParams();
  const { projects } = useSelector((state) => state.projects);
  const details = projects.filter((i) => i.id == id);
  const { t } = useTranslation("global");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsImg, setModalIsImg] = useState("");

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="container-xxl py-5">
      <div className="container">
        {details.map((item, key) => {
          const Img = item.images;

          return (
            <div
              key={key}
              className="row g-5 align-items-center justify-content-center"
            >
              <div
                className="col-lg-12 wow fadeIn d-flex justify-content-center w-75 h-75"
                data-wow-delay="0.1s "
              >
                <div className="about-img position-relative overflow-hidden p-5 pe-0">
                  <Swiper
                    modules={[
                      Navigation,
                      Pagination,
                      Scrollbar,
                      A11y,
                      Autoplay,
                    ]}
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation
                    pagination={{ clickable: true }}
                    autoplay={{ delay: 3000 }}
                    className="header-swiper"
                  >
                    {Img.map((item, key) => (
                      <SwiperSlide key={key}>
                        <img
                          className="img-fluid w-100 h-100"
                          src={`https://sima.alayadi.site/project_imgs/${item.photo}`}
                          alt={t("project_details.image_alt", "Project Image")}
                          onClick={() => {
                            openModal(true);
                            setModalIsImg(`${item.photo}`);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
              <div
                dir={t("dir")}
                className="col-lg-12 wow fadeIn"
                data-wow-delay="0.5s"
              >
                <h1 className="mb-4">{item[t("property.title")]}</h1>
                <p
                  className="mb-4"
                  dangerouslySetInnerHTML={{ __html: item[t("property.desc")] }}
                ></p>
              </div>
              <div
                dir={t("dir")}
                className="col-lg-12 wow fadeIn"
                data-wow-delay="0.5s"
              >
                <ul className={`${t("text")}`}>
                  <li>
                    <strong>{t("project_details.category")}:</strong>{" "}
                    {item.categori[t("category.categori")]}
                  </li>
                  <li>
                    <strong>{t("project_details.status")}:</strong>{" "}
                    {item[t("property.ready")]}
                  </li>
                  <li>
                    <strong>{t("project_details.date")}:</strong> {item.date}
                  </li>
                  <li>
                    <strong>{t("project_details.rooms")}:</strong> {item.room}
                  </li>
                  <li>
                    <strong>{t("project_details.bathrooms")}:</strong>{" "}
                    {item.lavabo}
                  </li>
                  <li>
                    <strong>{t("project_details.area")}:</strong> {item.m2} م²
                  </li>
                  <li>
                    <strong>{t("project_details.empty_status")}:</strong>{" "}
                    {item[t("property.empty")]}
                  </li>
                  <li>
                    <strong>{t("project_details.offer_type")}:</strong>{" "}
                    {item[t("property.sell")]}
                  </li>
                  <li>
                    <strong>{t("property.price")}:</strong> ${item.price}
                  </li>
                </ul>
              </div>
            </div>
          );
        })}
      </div>
      {modalIsOpen && (
        <div className="modal-overlay w-100 h-100" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="btn-close" onClick={closeModal}>
              &times;
            </button>
            <img
              className="img-fluid modal-image"
              src={`https://sima.alayadi.site/project_imgs/${modalIsImg}`}
              alt={t("project_details.image_alt", "Project Image")}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Projectdetails;
