import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { animateScroll as scroll } from "react-scroll";

const Footer = () => {
  const { t } = useTranslation("global");
  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 50,
      smooth: "easeInOutQuint",
    });
  };
  return (
    <>
      {/* Footer Start */}
      <div
        className="container-fluid bg-dark text-white-50 footer pt-5 mt-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-6 col-md-6">
              <h5 className="text-white mb-4">{t("footer.get_in_touch")}</h5>
              <p className="mb-2">
                <i className="fa fa-map-marker-alt me-3" />
                {t("footer.address")}
              </p>
              <p className="mb-2">
                <i className="fa fa-phone-alt me-3" />
                {t("footer.phone")}
              </p>
              <p className="mb-2">
                <i className="fa fa-envelope me-3" />
                {t("footer.email")}
              </p>
              <div className="d-flex pt-2">
                <a
                  href="http://instagram.com/simagrup54"
                  className="btn btn-outline-light btn-social"
                >
                  <i className="fab fa-twitter" />
                </a>
                <a
                  href="http://Facebook.com/simagrup5"
                  className="btn btn-outline-light btn-social"
                >
                  <i className="fab fa-facebook-f" />
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <h5 className="text-white mb-4">{t("footer.quick_links")}</h5>
              <Link
                onClick={() => {
                  scrollToTop();
                }}
                to={"/"}
                className="btn btn-link text-white-50"
              >
                {t("footer.home")}
              </Link>
              <Link
                onClick={() => {
                  scrollToTop();
                }}
                to={"/about"}
                className="btn btn-link text-white-50"
              >
                {t("footer.about")}
              </Link>
              <Link
                onClick={() => {
                  scrollToTop();
                }}
                to={"/projects"}
                className="btn btn-link text-white-50"
              >
                {t("footer.projects")}
              </Link>
              <Link
                onClick={() => {
                  scrollToTop();
                }}
                to={"/news"}
                className="btn btn-link text-white-50"
              >
                {t("footer.news")}
              </Link>
              <Link
                onClick={() => {
                  scrollToTop();
                }}
                to={"/contact"}
                className="btn btn-link text-white-50"
              >
                {t("footer.contact_us")}
              </Link>
            </div>
            {/* <div className="col-lg-4 col-md-6">
              <h5 className="text-white mb-4">Photo Gallery</h5>
              <div className="row g-2 pt-2">
                <div className="col-4">
                  <img
                    className="img-fluid rounded bg-light p-1"
                    src="img/property-1.jpg"
                    alt=""
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid rounded bg-light p-1"
                    src="img/property-2.jpg"
                    alt=""
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid rounded bg-light p-1"
                    src="img/property-3.jpg"
                    alt=""
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid rounded bg-light p-1"
                    src="img/property-4.jpg"
                    alt=""
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid rounded bg-light p-1"
                    src="img/property-5.jpg"
                    alt=""
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid rounded bg-light p-1"
                    src="img/property-6.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                {t("footer.rights_reserved")}
                <a className="border-bottom" href="https://alayadids.com">
                  {t("footer.alayadi_digital_solutions")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer End */}
    </>
  );
};

export default Footer;
