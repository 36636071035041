import React from "react";
import { useTranslation } from "react-i18next";
import Img from "../../images/about.jpg";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const NewsDetails = () => {
  const { t } = useTranslation("global");
  const { news } = useSelector((state) => state.news);
  const { id } = useParams();
  const details = news.filter((i) => i.id == id);
  console.log(details);
  return (
    <div dir={t("dird")} className="container-xxl py-5">
      <div className="container">
        <div className="bg-light rounded p-2">
          <div
            className="bg-white rounded p-2"
            style={{ border: "1px dashed rgba(0, 185, 142, .3)" }}
          >
            {details.map((item, key) => {
              return (
                <div key={key} className="row g-5 align-items-center">
                  <div
                    className="col-lg-12 wow fadeIn text-center"
                    data-wow-delay="0.1s"
                  >
                    <img
                      className="img-fluid rounded w-lg-50  "
                      src={`https://sima.alayadi.site/news_image/${item.photo}`}
                      alt=""
                    />
                  </div>
                  <div
                    dir={t("dir")}
                    className="col-lg-12 wow fadeIn"
                    data-wow-delay="0.5s"
                  >
                    <div className="mb-4">
                      <h1 className="mb-3 text-center">
                        {item[t("news.title")]}
                      </h1>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item[t("news.desc")],
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsDetails;
