import React from "react";
import Header from "./Header";
import Category from "./Category";
import About from "./About";
import Projects from "./Projects";
import News from "./News";

const Home = () => {
  return (
    <>
      <Header />
      <About />
      <Category />
      <Projects />
      <News />
    </>
  );
};

export default Home;
