import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function Category() {
  const { t } = useTranslation("global");
  const { categories } = useSelector((state) => state.categories);

  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div
          className="text-center mx-auto mb-5 wow fadeInUp"
          data-wow-delay="0.1s"
          style={{ maxWidth: 600 }}
        >
          <h1 className="mb-3">{t("category.heading")}</h1>
          <p>{t("category.description")}</p>
        </div>
        <div className="row g-4">
          {categories.map((item, key) => {
            const Icons =
              item.categori_name === "Villa"
                ? "icon-villa.png"
                : item.categori_name === "Daire"
                ? "icon-condominium.png"
                : item.categori_name === "Apartman"
                ? "icon-building.png"
                : "icon-house.png";
            return (
              <div
                key={key}
                className="col-lg-3 col-sm-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <a className="cat-item d-block bg-light text-center rounded p-3">
                  <div className="rounded p-4">
                    <div className="icon mb-3">
                      <img
                        className="img-fluid"
                        src={`img/${Icons}`}
                        alt={t("category.icon_alt")}
                      />
                    </div>
                    <h6>{item[t("category.categori")]}</h6>
                    <span>
                      {t("category.properties_count", {
                        count: item.projects.length,
                      })}
                    </span>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Category;
