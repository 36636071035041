import React from "react";
import { useTranslation } from "react-i18next";
import Img from "../../images/about.jpg";

const About = () => {
  const { t } = useTranslation("global");

  return (
    <div dir={t("dird")} className="container-xxl py-5">
      <div className="container">
        <div className="bg-light rounded p-2">
          <div
            className="bg-white rounded p-2"
            style={{ border: "1px dashed rgba(0, 185, 142, .3)" }}
          >
            <div className="row g-5 align-items-center">
              <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                <img className="img-fluid rounded w-100" src={Img} alt="" />
              </div>
              <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                <div className="mb-4">
                  <h1 className="mb-3 text-center">{t("about.heading")}</h1>
                  <p className="text-center">{t("about.description1")}</p>
                  <p className="text-center">{t("about.description2")}</p>
                  <h1 className="mb-3 text-center">{t("about.vision")}</h1>
                  <p className="text-center">{t("about.vision_desc")}</p>
                </div>
              </div>
              <div className="col-lg-12 wow fadeIn" data-wow-delay="0.1s">
                <h1 className="mb-2 text-center">
                  {t("about.values_heading")}
                </h1>
                <p className="text-center">{t("about.values_desc")}</p>
                <ul dir={t("dir")} className={`${t("text")}`}>
                  <li>{t("about.values.efficiency")}</li>
                  <li>{t("about.values.integrity")}</li>
                  <li>{t("about.values.fairness")}</li>
                  <li>{t("about.values.transparency")}</li>
                  <li>{t("about.values.social_responsibility")}</li>
                </ul>
                <h1 className="mb-2 text-center">{t("about.goals_heading")}</h1>
                <p className="text-center">{t("about.goals_desc")}</p>
                <ul dir={t("dir")} className={`${t("text")}`}>
                  <li>{t("about.goals.best_company")}</li>
                  <li>{t("about.goals.high_quality_services")}</li>
                  <li>{t("about.goals.best_experience")}</li>
                  <li>{t("about.goals.constant_improvement")}</li>
                  <li>{t("about.goals.economic_development")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
