import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

function News() {
  const { t } = useTranslation("global");
  const { news } = useSelector((state) => state.news);

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 50,
      smooth: "easeInOutQuint",
    });
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div
          className="text-center mx-auto mb-5 wow fadeInUp"
          data-wow-delay="0.1s"
          style={{ maxWidth: 600 }}
        >
          <h1 className="mb-3">{t("news.latest_news")}</h1>
          <p>{t("news.description")}</p>
        </div>
        <div className="row g-4">
          {news.map((item, key) => (
            <div
              key={key}
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <Link
                onClick={() => {
                  scrollToTop();
                }}
                to={`/news_details/${item.id}`}
              >
                <div className="team-item rounded overflow-hidden h-100">
                  <div className="position-relative">
                    <img
                      className="img-fluid"
                      src={`https://sima.alayadi.site/news_image/${item.photo}`}
                      alt=""
                    />
                  </div>
                  <div className="text-center p-4 mt-3">
                    <h5 className="fw-bold mb-0">{item[t("news.title")]}</h5>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default News;
