import React from "react";
import { useTranslation } from "react-i18next";
import Img from "../../images/nationality.jpg";

const Nationality = () => {
  const { t } = useTranslation("global");

  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="bg-light rounded p-3">
          <div
            dir={t("dir")}
            className="bg-white rounded p-4"
            style={{ border: "1px dashed rgba(0, 185, 142, .3)" }}
          >
            <div className="row g-5 align-items-center">
              <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                <img className="img-fluid rounded w-100" src={Img} alt="" />
              </div>
              <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                <div className="mb-4">
                  <h1 className="mb-3">{t("nationality.heading")}</h1>
                  <p>{t("nationality.description1")}</p>
                  <p>{t("nationality.services")}</p>
                  <p>{t("nationality.service1")}</p>
                  <p>{t("nationality.service2")}</p>
                  <p>{t("nationality.service3")}</p>
                  <p>{t("nationality.service4")}</p>
                  <p>{t("nationality.description2")}</p>
                  <p>{t("nationality.description3")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nationality;
